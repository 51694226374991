import { Button, IconButton } from '@/components/ui/Button'
import { FileInput } from '@/components/ui/Input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { humanReadableTimeOrDate } from '@/lib/dates'
import { DownloadIcon, TrashIcon } from 'lucide-react'
import { useState } from 'react'

// Dummy attachments data
const DUMMY_ATTACHMENTS = [
  {
    fileId: 1,
    uploadDatetime: new Date().toISOString(),
    url: 'https://example.com/files/report1.pdf',
    filename: 'report1.pdf',
  },
  {
    fileId: 2,
    uploadDatetime: new Date(Date.now() - 86400000).toISOString(),
    url: 'https://example.com/files/notes.docx',
    filename: 'notes.docx',
  },
]

export const AttachmentsTable = ({ worklistId }: { worklistId: number }) => {
  const [attachment, setAttachment] = useState<File | null>(null)
  const files = DUMMY_ATTACHMENTS

  const handleUpload = async () => {
    if (!attachment) return
    // TODO:
    console.log('Would upload:', attachment.name)
    setAttachment(null)
  }

  const handleDownload = async (fileId: number) => {
    // TODO:
    console.log('Would download file:', fileId)
  }

  const handleDelete = async (fileId: number) => {
    // TODO:
    console.log('Would delete file:', fileId)
  }

  return (
    <div className='flex flex-col gap-4'>
      <Table className='w-full'>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Filename</TableHead>
            <TableHead className='text-right'>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {files.map((f) => (
            <TableRow key={f.fileId}>
              <TableCell className='max-w-[55px] text-ellipsis whitespace-nowrap overflow-hidden'>
                {humanReadableTimeOrDate(new Date(f.uploadDatetime))}
              </TableCell>
              <TableCell className='max-w-[150px] text-ellipsis whitespace-nowrap overflow-hidden'>
                {f.filename}
              </TableCell>
              <TableCell className='text-right whitespace-nowrap'>
                <IconButton
                  icon={<DownloadIcon className='w-4 h-4' />}
                  onClick={() => handleDownload(f.fileId)}
                />
                <IconButton
                  icon={<TrashIcon className='w-4 h-4' />}
                  onClick={() => handleDelete(f.fileId)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <>
        <FileInput selectedFile={attachment} onChange={setAttachment} />
        <Button variant='outline' disabled={!attachment} onClick={handleUpload}>
          Upload Attachment
        </Button>
      </>
    </div>
  )
}
