import logo from '@/assets/EpsilonHealth-MainLogo.png'
import { Button } from '@/components/ui/Button'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col items-center justify-center h-screen text-4xl font-light'>
      <img src={logo} alt='Epsilon Health' className='h-[30px]' draggable={false} />
      <h1 className='text-6xl font-light my-4'>Page not found.</h1>
      <Button
        variant='outline'
        color='primary'
        className='mt-4'
        onClick={() => navigate('/', { replace: true })}>
        Go Home
      </Button>
    </div>
  )
}

export default NotFound
