import { humanReadableDate } from '@/lib/dates'
import { cn } from '@/lib/utils'
// import { useAuthStore } from "@/state/AuthState";
// import { useGlobalState } from "@/state/GlobalState";
import { StatusBubble, StatusColor } from '@/components/ui/Status'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { GalleryVerticalEnd, MessageCircle, Paperclip } from 'lucide-react'
import moment from 'moment-timezone'
import { RefObject } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiStudy, UserRole } from 'shared'
import voca from 'voca'

const StudiesTable = ({
  studies,
  selectedStudyId,
  selectedStudyRowRef,
}: {
  studies: ApiStudy[]
  selectedStudyId: number
  selectedStudyRowRef: RefObject<HTMLTableRowElement>
}) => {
  //const { role } = useAuthStore.getState();
  const role: UserRole = 'admin'

  if (studies.length === 0) {
    return <div className='text-lg text-zinc-500 text-center'>No studies found</div>
  }

  return (
    <Table className='mobile-table overflow-x-auto'>
      <TableHeader>
        <TableRow className='hover:bg-transparent hover:cursor-default select-none'>
          <TableHead />
          <TableHead>Date</TableHead>
          {role === 'admin' && <TableHead>Facility</TableHead>}
          <TableHead>Patient</TableHead>
          <TableHead className='text-center'>Modality</TableHead>
          <TableHead>Description</TableHead>
          <TableHead>
            <GalleryVerticalEnd className='w-4 h-4' />
          </TableHead>
          <TableHead>
            <Paperclip className='w-4 h-4' />
          </TableHead>
          <TableHead>
            <MessageCircle className='w-4 h-4' />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {studies.map((study) => (
          <DataRow
            key={study.studyUid}
            study={study}
            selected={selectedStudyId === study.worklistId}
            selectedStudyRowRef={selectedStudyRowRef}
            status={(() => {
              // switch (role) {
              //   case "physician-group":
              //     return study.priority;
              //   default:
              return study.status
              //}
            })()}
            statusColor={(() => {
              switch (role) {
                case 'admin':
                  return 'transparent'
                // case "facility":
                //   if (/^pending/i.test(study.status ?? "")) {
                //     return "yellow";
                //   }
                //   return "transparent";
                // case "physician-group":
                //   if (/^stat/i.test(study.priority ?? "")) {
                //     return "red";
                //   }
                //   return "transparent";
                default:
                  return 'transparent'
              }
            })()}
          />
        ))}
      </TableBody>
    </Table>
  )
}

const DataRow = ({
  study,
  selected,
  selectedStudyRowRef,
  status,
  statusColor,
}: {
  study: ApiStudy
  selected: boolean
  selectedStudyRowRef?: RefObject<HTMLTableRowElement>
  status: string | null
  statusColor: string
}) => {
  const navigate = useNavigate()
  //const mouseIsDown = useGlobalState((s) => s.mouseIsDown);
  const studyDate = moment(study.studyDatetime)
  const isStat = study.priority === 'STAT'
  //const role = useAuthStore(u => u.role);
  const role: UserRole = 'admin'

  let bg = ''
  if (selected) {
    bg = isStat ? 'bg-red-100 hover:bg-red-200' : 'bg-slate-100 hover:bg-slate-200'
  }

  return (
    <TableRow
      className={cn(
        //mouseIsDown && "select-none",
        study.priority === 'STAT' && 'bg-red-50 hover:bg-red-100 text-red-900',
        bg
      )}
      onClick={(event: any) => {
        event.preventDefault()
        navigate('/studies/' + study.worklistId)
      }}
      ref={selected ? selectedStudyRowRef : undefined}>
      <TableCell
        className='w-[40px] whitespace-nowrap'
        tooltip={`Status: ${status}`}
        data-cell='status'>
        <StatusBubble className='ml-2' color={statusColor as StatusColor} />
      </TableCell>
      <TableCell className='w-[80px] text-left whitespace-nowrap' data-cell='date'>
        {studyDate.isSame(moment(), 'day')
          ? studyDate.format('h:mm A')
          : humanReadableDate(studyDate.toDate())}
      </TableCell>
      {role === 'admin' && (
        <TableCell
          className='text-left max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis'
          data-cell='facility'>
          {study.facilityName}
        </TableCell>
      )}
      <TableCell
        className='text-left max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis'
        data-cell='patient'>
        {voca.titleCase(study.patientName ?? 'N/A')}
      </TableCell>
      <TableCell className='w-[40px] text-center whitespace-nowrap' data-cell='class'>
        {study.modalityCode}
      </TableCell>
      <TableCell
        className='text-left max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis'
        data-cell='procedure'>
        {study.modalityProcedure ?? <div className='italic font-light'>No Description</div>}
      </TableCell>
      <TableCell
        className='w-[20px] text-center whitespace-nowrap'
        tooltip={`${study.seriesCount} Scans`}
        data-cell='images'>
        {study.seriesCount}
      </TableCell>
      <TableCell
        className='w-[20px] text-center whitespace-nowrap'
        tooltip={`${study.documentsCount} Attachments`}
        data-cell='documents'>
        {study.documentsCount}
      </TableCell>
      <TableCell
        className='w-[20px] text-center whitespace-nowrap'
        tooltip={`${study.messagesCount} Messages`}
        data-cell='images'>
        {study.messagesCount}
      </TableCell>
    </TableRow>
  )
}

export default StudiesTable
