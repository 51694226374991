import { UserRole } from 'shared'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface AuthState {
  user: string | null
  role: UserRole | null
  token: string | null
  refreshToken: string | null
  setUser: (user: string, role: UserRole, token: string, refreshToken: string) => void
  clearUser: () => void
}

export const useAuthStore = create(
  persist<AuthState>(
    (set) => ({
      user: null,
      role: null,
      token: null,
      refreshToken: null,
      setUser: (user: string, role: UserRole, token: string, refreshToken: string) => {
        set({ user, role, token, refreshToken })
      },
      clearUser: () => {
        set({
          user: null,
          role: null,
          token: null,
          refreshToken: null,
        })
      },
    }),
    {
      name: 'auth-storage',
    }
  )
)
