import dummyData from '@/assets/dummy_data.json'
import { ListFieldStacked as ListField } from '@/components/ui/List'
import moment from 'moment'

export const ExtraDetails = ({ worklistId }: { worklistId: number }) => {
  const study = dummyData.studies.find((s) => s.worklistId === worklistId)

  if (!study) {
    return <div>Study not found</div>
  }

  return (
    <>
      <ListField label='Other'>
        <ul className='text-sm text-slate-400'>
          <li>Status: {study.status}</li>
          <li>Priority: {study.priority}</li>
          <li>Series Count: {study.seriesCount}</li>
          <li>Images Count: {study.imagesCount}</li>
          <li>Documents Count: {study.documentsCount}</li>
          <li>Messages Count: {study.messagesCount}</li>
        </ul>
      </ListField>
      <ListField label='Dates'>
        <div className='text-sm text-slate-400'>
          {study.receivedDatetime && (
            <div>Received: {moment(study.receivedDatetime).format('M-D-YYYY h:mm A')}</div>
          )}
          {study.studyDatetime && (
            <div>Study: {moment(study.studyDatetime).format('M-D-YYYY h:mm A')}</div>
          )}
          {study.requestedDatetime && (
            <div>Requested: {moment(study.requestedDatetime).format('M-D-YYYY h:mm A')}</div>
          )}
          {study.completedDatetime && (
            <div>Completed: {moment(study.completedDatetime).format('M-D-YYYY h:mm A')}</div>
          )}
        </div>
      </ListField>
    </>
  )
}
