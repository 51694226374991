import { Button } from '@/components/ui/Button'
import { Input, PhoneNumberInput } from '@/components/ui/Input'
import { Textarea } from '@/components/ui/Textarea'
import _ from 'lodash'
import { useEffect, useState } from 'react'
// import { PhysicianComboBox } from "@/components/StudiesView/PhysiciansComboBox";
// import { PatientsComboBox } from "@/components/StudiesView/PatientsComboBox";
// import { PhysicianGroupSelect } from "@/components/StudiesView/PhysicianGroupSelect";
import { FileInput } from '@/components/ui/Input'
import { ListContainer, ListFieldStacked as ListField } from '@/components/ui/List'
import { Loader2 } from 'lucide-react'
import { ApiPatient, ApiPhysician, ApiStudy } from 'shared'
//import voca from "voca";

export const EditStudyDetails = ({
  selectedStudy,
  onSave,
  onCancel,
}: {
  selectedStudy: ApiStudy
  onSave: (study: Partial<ApiStudy>) => void
  onCancel: () => void
}) => {
  //const originalPatient = selectedStudy.patient;
  const [patient, setPatient] = useState<Partial<ApiPatient>>(
    selectedStudy.patient ?? { patientId: selectedStudy.patientId! }
  )
  const [referringPhysician, setReferringPhysician] = useState<Partial<ApiPhysician>>(
    selectedStudy.referringPhysician ?? { physicianId: selectedStudy.referringPhysicianId! }
  )
  const [physicianGroupId, setPhysicianGroup] = useState<number | null>(
    referringPhysician?.physicianGroupId ?? null
  )
  const [faxNumber, setFaxNumber] = useState(referringPhysician?.fax)
  const [procedure, setProcedure] = useState(selectedStudy.modalityProcedure)
  const [history, setHistory] = useState(selectedStudy.reason)
  const [attachment, setAttachment] = useState<File | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isStat, setIsStat] = useState(false)

  useEffect(() => {
    setPatient(selectedStudy.patient ?? { patientId: selectedStudy.patientId! })
    setReferringPhysician(
      selectedStudy.referringPhysician ?? { physicianId: selectedStudy.referringPhysicianId! }
    )
    setProcedure(selectedStudy.modalityProcedure)
    setHistory(selectedStudy.reason)
    setIsStat(selectedStudy.priority === 'STAT')
    setAttachment(null)
  }, [selectedStudy])

  useEffect(() => {
    setPhysicianGroup(referringPhysician?.physicianGroupId ?? null)
  }, [referringPhysician])

  useEffect(() => {
    setPhysicianGroup(referringPhysician?.physicianGroupId ?? null)
  }, [referringPhysician])

  async function handleSave() {
    setIsSaving(true)
    try {
      await onSave(
        _.pickBy({
          patientId: patient?.patientId,
          referringPhysicianId: referringPhysician?.physicianId,
          physicianGroupId: physicianGroupId,
          fax: faxNumber,
          modalityProcedure: procedure,
          reason: history,
          attachment: attachment,
          priority: isStat ? 'STAT' : 'ROUTINE',
        })
      )
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <ListContainer className='flex flex-col gap-1'>
      <Button
        className={`flex-1 ml-3 border-red-200 text-red-900 ${
          isStat && 'bg-red-50'
        } hover:bg-red-50 hover:text-red-900`}
        variant='outline'
        onClick={() => setIsStat(!isStat)}>
        Mark as STAT
      </Button>

      <ListField label='Procedure'>
        <Input
          id='procedure'
          value={procedure ?? ''}
          onChange={(e) => setProcedure(e.target.value)}
          className='p-2 h-9'
        />
      </ListField>

      {/* <ListField label="Patient">
        <div className="flex flex-row">
          <PatientsComboBox
            className="flex-1"
            patient={patient as ApiPatient}
            originalPatient={originalPatient}
            onChange={ setPatient }
          />
        </div>
      </ListField>

      <ListField label="Physician">
        <PhysicianComboBox
          physician={ referringPhysician as ApiPhysician }
          showOriginalPhysName={true}
          originalPhysName={ referringPhysician?.name ?? "N/A" }
          onChange={ setReferringPhysician }
        />
      </ListField>

      <ListField label="Physician Group">{
        !referringPhysician.physicianGroupId ? (
          <PhysicianGroupSelect
            selectedGroupID={physicianGroupId ?? undefined}
            onChange={group => {
              setPhysicianGroup(group?.physicianGroupId ?? null);
              if (!referringPhysician.fax) setFaxNumber(group?.fax);
            }}
          />
        ) :
        <div className="text-[16px]">
          { voca.titleCase(referringPhysician.physicianGroup ?? "Unknown" ) }
        </div>
      }</ListField>
      */}

      <ListField label='Physician Fax Number'>
        <PhoneNumberInput value={faxNumber ?? null} onChange={(value) => setFaxNumber(value)} />
      </ListField>

      <ListField label='Clinical History'>
        <Textarea
          className='p-2'
          value={history ?? ''}
          onChange={(e) => setHistory(e.target.value)}
        />
      </ListField>

      {selectedStudy.modalityCode === 'US' && (
        <ListField label='Tech Sheet'>
          <FileInput selectedFile={attachment} onChange={setAttachment} />
        </ListField>
      )}

      <div className='flex flex-row gap-4 pt-4 ml-1'>
        <Button
          variant='outline'
          className='flex-1 flex flex-row items-center'
          onClick={handleSave}
          disabled={isSaving}>
          {isSaving ? (
            <>
              <Loader2 className='w-4 h-4 mr-2 animate-spin' />
              <div className='flex-1'>Saving...</div>
            </>
          ) : (
            <div className='flex-1'>Save</div>
          )}
        </Button>

        {
          /* Don't allow cancel when the study is pending */
          selectedStudy.status !== 'PENDING' && (
            <Button variant='outline' className='flex-1' onClick={onCancel}>
              Cancel
            </Button>
          )
        }
      </div>
    </ListContainer>
  )
}
