import icon from '@/assets/EpsilonHealth-MainIcon.png'
import { auth } from '@/firebase'
import { useAuthStore } from '@/state/authState'
import { signOut } from 'firebase/auth'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'

const Navbar = () => {
  const user = useAuthStore((state) => state.user)
  const location = useLocation()
  const isDicomViewerView =
    location.pathname.includes('/studies/') && location.pathname.split('/').length > 3

  return (
    <nav className='flex h-[3.5rem] w-full items-center border-b-[1px] border-solid'>
      <div
        className={`flex m-auto h-[3.5rem] w-full items-center justify-center px-6 gap-2 ${
          isDicomViewerView ? 'max-w-none' : 'max-w-[1600px]'
        }`}>
        <Brand />
        {user && <NavLinks />}
        <div className='h-full w-full' />
        {user && <AccountInfo />}
      </div>
    </nav>
  )
}

const NavLinks = () => {
  const role = useAuthStore((state) => state.role)

  return (
    <div className='hidden h-full grow items-center gap-5 self-end p-0 md:flex'>
      <NavElement label='Studies' to='studies' useHome />
      <NavElement label='Patients' to='patients' />
      <NavElement label='Physicians' to='physicians' secondary='groups' />
      {role === 'admin' && (
        <>
          <NavElement label='Facilities' to='facilities' />
          <NavElement label='Users' to='users' />
        </>
      )}
      {role !== 'physician-group' && <NavElement label='Reports' to='reports' />}
    </div>
  )
}

const AccountInfo = () => {
  const clearUser = useAuthStore((state) => state.clearUser)
  const user = useAuthStore((state) => state.user)
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      await signOut(auth)
      clearUser()
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  return (
    <ul className=' flex flex-row gap-2 items-center'>
      <li className='text-zinc-400 whitespace-nowrap select-none'>Welcome, {user}</li>
      <li className='trasition-all duration-300 cursor-pointer w-[5em] text-center text-zinc-700 hover:text-zinc-950 hover:bg-zinc-100 hover:rounded-md p-1'>
        <span onClick={handleLogout}>Log out</span>
      </li>
    </ul>
  )
}

const Brand = () => {
  return (
    <div className=' hidden md:flex mr-2 font-bold text-xl flex-row shrink-0 items-center'>
      <Link to='/' className='mr-2 flex items-center gap-2'>
        <img
          src={icon}
          alt='Epsilon Health'
          className='h-[30px] object-contain transition-transform duration-500 hover:scale-105'
          draggable={false}
        />
      </Link>
    </div>
  )
}

const NavElement = ({
  label,
  to,
  secondary,
  useHome,
  onClick,
}: {
  label: string
  to: string
  secondary?: string
  useHome?: boolean
  onClick?: () => void
}) => {
  const isHome = useMatch({
    path: '/',
  })
  const selectedA = useMatch({
    path: to,
    end: false,
  })
  const selectedB = useMatch({
    path: secondary ?? 'never',
    end: false,
  })

  const isSelected = (isHome && useHome) || selectedA || selectedB

  return (
    <li
      className={`relative h-full flex items-center group ${
        isSelected ? 'text-black' : 'hover:text-black text-gray-600'
      }`}>
      <Link to={to} onClick={onClick} className='px-1'>
        {label}
      </Link>
      <div
        className={`absolute bottom-0 left-0 w-full h-0.5 transition-opacity duration-300 ${
          isSelected ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
        } bg-black`}
      />
    </li>
  )
}

export default Navbar
