import dummyData from '@/assets/dummy_data.json'
import { EditStudyDetails } from '@/components/StudiesView/EditStudyDetails'
import { ExtraDetails } from '@/components/StudiesView/ExtraDetails'
import { StudyOverview } from '@/components/StudiesView/StudyOverview'
import { Button, IconButton } from '@/components/ui/Button'
import { humanReadableDateAndYear } from '@/lib/dates'
import { PenLine, X } from 'lucide-react'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiStudy } from 'shared'

export const StudyDetailPanel = ({ className }: { className?: string }) => {
  const navigate = useNavigate()
  const params = useParams()
  const worklistId = parseInt(params.worklistId!)
  const [isEditing, setIsEditing] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [doEdit, setDoEdit] = useState(false)

  // Find the study in dummy data
  const study = dummyData.studies.find((s) => s.worklistId === worklistId)

  // Convert dates for the found study using useMemo
  const studyWithDates = useMemo(
    () =>
      study
        ? {
            ...study,
            requestedDatetime: study.requestedDatetime ? new Date(study.requestedDatetime) : null,
            completedDatetime: study.completedDatetime ? new Date(study.completedDatetime) : null,
            receivedDatetime: study.receivedDatetime ? new Date(study.receivedDatetime) : null,
            studyDatetime: study.studyDatetime ? new Date(study.studyDatetime) : null,
          }
        : undefined,
    [study]
  )

  const isAdmin = true
  const isFacility = false

  useEffect(() => setIsEditing(false), [worklistId])
  useEffect(() => setIsPending(studyWithDates?.status === 'pending'), [studyWithDates])
  useEffect(() => setIsEditing(isPending ? true : doEdit), [isPending, doEdit])

  const handleUpdateStudy = async (data: Partial<ApiStudy>) => {
    // TODO:
    console.log('Would update study with:', data)
    setDoEdit(false)
  }

  const handleClose = () => {
    navigate('/studies')
  }

  if (!studyWithDates) {
    return <div className='italic'>Study not found</div>
  }

  const studyDate = moment(studyWithDates.studyDatetime)
  const { modalityProcedure } = studyWithDates

  return (
    <div className='fixed top-0 right-0 h-screen w-[600px] bg-white shadow-xl z-30 flex flex-col'>
      {/* Header */}
      <div className='flex items-center justify-between px-6 py-4'>
        <div>
          <h2 className='text-lg font-semibold'>{modalityProcedure ?? 'No Description'}</h2>
          <p className='text-sm text-gray-500'>{humanReadableDateAndYear(studyDate.toDate())}</p>
        </div>
        <div className='flex gap-2'>
          {(isAdmin || isFacility) && !isEditing && (
            <Button variant='ghost' onClick={() => setDoEdit(true)}>
              <PenLine className='h-4 w-4 mr-2' />
              Edit
            </Button>
          )}
          <IconButton icon={<X className='h-4 w-4' />} onClick={handleClose} />
        </div>
      </div>

      {/* Content */}
      <div className='flex-1 overflow-y-auto px-6 py-4'>
        {isPending && (
          <>
            <div className='border-2 rounded-lg bg-yellow-50 border-yellow-200 text-yellow-900 p-4 mb-4'>
              This study is <strong>pending</strong>. Fill in the details below to complete the
              study.
            </div>
            <Button
              className='w-full mb-4'
              variant='outline'
              onClick={() => navigate(`/studies/${worklistId}/viewer`)}
              disabled={true}>
              View Images
            </Button>
          </>
        )}

        <div className='space-y-4'>
          {isEditing ? (
            <EditStudyDetails
              selectedStudy={studyWithDates}
              onSave={handleUpdateStudy}
              onCancel={() => setDoEdit(false)}
            />
          ) : (
            <StudyOverview study={studyWithDates} navigate={navigate} dicoms={[]} />
          )}
        </div>

        <div className='border-t mt-8 pt-4'>
          <ExtraDetails worklistId={worklistId} />
        </div>
      </div>
    </div>
  )
}
