import moment from 'moment'

export function parsePromptRadDate(text: string | null) {
  if (typeof text === 'string') {
    const matches = text.match(/\/Date\((\d+)\)\//)
    if (matches && matches[1]) {
      const timestamp = parseInt(matches?.[1], 10)
      return new Date(timestamp)
    }
  }
  return new Date()
}

export function postgresDateToNiceDate(date: Date | string | undefined): string | undefined {
  if (!date) return undefined
  return moment(date).format('M-D-YYYY')
}

export function humanReadableDateAndYear(date: Date | string | undefined): string | undefined {
  if (!date) return undefined
  return moment(date).format('MMM DD, YYYY')
}

export function humanReadableDate(date: Date) {
  if (!moment(date).isValid()) return 'N/A'
  return moment(date).isSame(new Date(), 'day')
    ? 'Today'
    : moment(date).add(1, 'day').isSame(new Date(), 'day')
    ? 'Yesterday'
    : moment(date).isSame(new Date(), 'week')
    ? moment(date).format('dddd')
    : moment(date).isSame(new Date(), 'year')
    ? moment(date).format('MMM D')
    : moment(date).format('MM/DD/YYYY')
}

export function humanReadableTime(date: Date) {
  if (!moment(date).isValid()) return 'N/A'
  return moment(date).format('h:mm A')
}

export function humanReadableDateAndTime(date: Date) {
  if (!moment(date).isValid()) return 'N/A'
  return `${humanReadableDate(date)} at ${humanReadableTime(date)}`
}

export function humanReadableTimeOrDate(date: Date) {
  if (!moment(date).isValid()) return 'N/A'
  return moment(date).isSame(new Date(), 'day') ? humanReadableTime(date) : humanReadableDate(date)
}
