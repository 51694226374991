import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/Table'
import { cn } from '@/lib/utils'
import { useState } from 'react'

// Dummy DICOM data
const DUMMY_DICOMS = [
  {
    seriesUid: '1.2.3.4.5.1.1',
    modality: 'CT',
    seriesDescription: 'Chest Without Contrast',
    sops: new Array(45).fill(null),
  },
  {
    seriesUid: '1.2.3.4.5.1.2',
    modality: 'CT',
    seriesDescription: 'Chest With Contrast',
    sops: new Array(45).fill(null),
  },
]

export const DicomStudyTable = ({ worklistId }: { worklistId: number }) => {
  const [selectedSeries, setSelectedSeries] = useState<string | null>(null)
  const dicoms = DUMMY_DICOMS

  return (
    <Table className='w-full'>
      <TableHeader>
        <TableRow>
          <TableHead>M.</TableHead>
          <TableHead>Description</TableHead>
          <TableHead>Images</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {dicoms.map((series) => (
          <TableRow
            key={series.seriesUid}
            className={cn({ 'bg-slate-100': selectedSeries === series.seriesUid })}
            onClick={() => setSelectedSeries(series.seriesUid)}>
            <TableCell>{series.modality}</TableCell>
            <TableCell className='max-w-[150px] text-ellipsis whitespace-nowrap overflow-hidden'>
              {series.seriesDescription ?? <div className='italic'>No Description</div>}
            </TableCell>
            <TableCell>{series.sops?.length ?? 0}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
