import icon from '@/assets/EpsilonHealth-MainIcon.png'

interface LoadingScreenProps {
  className?: string
}

const LoadingScreen = ({ className = 'h-[40px]' }: LoadingScreenProps) => {
  return (
    <div className='flex h-screen w-screen items-center justify-center'>
      <img
        src={icon}
        alt='Epsilon Health'
        className={`${className} animate-pulse`}
        draggable={false}
      />
    </div>
  )
}

export default LoadingScreen
