import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY,
  authDomain: `${process.env.REACT_APP_GOOGLE_CLOUD_PROJECT}.firebaseapp.com`,
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export { auth }
