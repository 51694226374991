import { LoadingSpinner } from '@/components/LoadingSpinner'
import StudiesTable from '@/components/StudiesView/StudiesTable'
import { StudyDetailPanel } from '@/components/StudiesView/StudyDetailPanel'
import { Input } from '@/components/ui/Input'
import { cn } from '@/lib/utils'
import { useAuthStore } from '@/state/authState'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

const parseIntOrNull = (str?: string) => {
  const result = str ? parseInt(str, 10) : null
  return result && !isNaN(result) ? result : null
}

const StudiesView = () => {
  const params = useParams()
  const selectedWorklistId = parseIntOrNull(params.worklistId)
  const scrollRef = useRef<HTMLDivElement>(null)
  const selectedStudyRowRef = useRef<HTMLTableRowElement>(null)
  const [searchText, setSearchText] = useState('')
  const token = useAuthStore((s) => s.token)

  const { data: studiesData, isLoading } = useQuery({
    queryKey: ['studies'],
    queryFn: async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/studies`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      
      // Parse date strings into Date objects
      return data.studies.map((study: any) => ({
        ...study,
        studyDatetime: study.studyDatetime ? new Date(study.studyDatetime) : null,
        requestedDatetime: study.requestedDatetime ? new Date(study.requestedDatetime) : null,
        completedDatetime: study.completedDatetime ? new Date(study.completedDatetime) : null,
        receivedDatetime: study.receivedDatetime ? new Date(study.receivedDatetime) : null
      }))
    },
    enabled: !!token
  })

  // Filter studies based on search text
  const filteredStudies = useMemo(() => {
    if (!studiesData) return []
    return studiesData.filter(
      (study: any) =>
        study.patientName?.toLowerCase().includes(searchText.toLowerCase()) ||
        study.facilityName?.toLowerCase().includes(searchText.toLowerCase()) ||
        study.modalityProcedure?.toLowerCase().includes(searchText.toLowerCase())
    )
  }, [searchText, studiesData])

  const updateSearchText = (text: string) => {
    setSearchText(text)
  }

  // Scroll selected study into view
  useEffect(() => {
    if (selectedStudyRowRef.current) {
      selectedStudyRowRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [selectedStudyRowRef])

  return (
    <div className='flex flex-col h-full w-full relative overflow-x-hidden'>
      <div
        className={cn(
          'mt-6 mb-8 transition-all duration-300 ease-in-out',
          selectedWorklistId ? 'md:pr-[450px]' : ''
        )}>
        <Input
          value={searchText}
          onChange={(e) => updateSearchText(e.target.value)}
          autoCapitalize='false'
          placeholder='Search Studies'
        />
      </div>
      {isLoading ? (
        <div className='flex justify-center w-full'>
          <LoadingSpinner />
        </div>
      ) : (
        <div
          id='scrollableStudiesDiv'
          className='w-full h-full overflow-y-auto overflow-x-hidden'
          ref={scrollRef}>
          <div
            className={cn(
              'flex flex-col w-full transition-all duration-300 ease-in-out',
              selectedWorklistId ? 'md:pr-[450px]' : ''
            )}>
            <div className='w-full'>
              <StudiesTable
                studies={filteredStudies}
                selectedStudyId={selectedWorklistId ?? 0}
                selectedStudyRowRef={selectedStudyRowRef}
              />
            </div>
          </div>
        </div>
      )}

      {!!selectedWorklistId && !isNaN(selectedWorklistId) && <StudyDetailPanel />}
    </div>
  )
}

export default StudiesView
