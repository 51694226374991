import { AttachmentsTable } from '@/components/StudiesView/AttachmentsTable'
import { DicomStudyTable } from '@/components/StudiesView/DicomStudyTable'
import { Button } from '@/components/ui/Button'
import { ListContainer, ListField, ListFieldStacked } from '@/components/ui/List'
import { TabBrowser } from '@/components/ui/TabView'
import _ from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ApiDicomSeries, ApiStudy } from 'shared'
import voca from 'voca'

export const StudyOverview = ({
  study,
  navigate,
  dicoms,
}: {
  study: ApiStudy
  navigate: (path: string) => void
  dicoms: ApiDicomSeries[]
}) => {
  const { worklistId } = study
  const [currentTab, setCurrentTab] = useState<'images' | 'files' | 'memos'>('images')
  const { status } = study

  const handleUpdateStudy = async (updates: Partial<ApiStudy>) => {
    // TODO:
    console.log('Would update study with:', updates)
  }

  return (
    <div>
      <ListContainer>
        <ListField label='Patient' labelClass='w-[140px]'>
          <Link
            to={`/patients/${study.patientId}`}
            className='text-blue-700 text-md hover:text-blue-500'>
            {voca.titleCase(study.patientName ?? 'N/A')}
          </Link>
        </ListField>

        <ListField label='Physician' labelClass='w-[140px]'>
          <div>
            <Link
              to={`/physicians/${study.referringPhysicianId}`}
              className='text-blue-700 text-md hover:text-blue-500'>
              {voca.titleCase(study.referringPhysicianName ?? 'N/A')}
            </Link>
          </div>
        </ListField>

        <ListField label='Physician Fax' labelClass='w-[140px]'>
          {study.referringPhysician?.fax ?? 'N/A'}
        </ListField>

        {study.reason && (
          <ListFieldStacked label='Clinical History'>
            {voca.capitalize(study.reason)}
          </ListFieldStacked>
        )}
      </ListContainer>

      <div className='py-4 flex flex-col gap-2'>
        <Button
          className={`w-full mx-2 border-red-200 text-red-900 hover:text-red-900 hover:bg-red-50 ${
            study.priority === 'STAT' ? 'bg-red-50' : ''
          }`}
          variant='outline'
          onClick={() =>
            handleUpdateStudy({ priority: study.priority === 'STAT' ? 'routine' : 'STAT' })
          }>
          {study.priority === 'STAT' ? 'Unmark as STAT' : 'Mark as STAT'}
        </Button>

        <Button
          className='w-full mx-2'
          variant='outline'
          onClick={() => navigate(`/studies/${worklistId}/viewer`)}
          disabled={!_.isArray(dicoms) || dicoms.length === 0}>
          View Images
        </Button>

        {/^Reported/.test(status ?? 'Unknown') && study.reportUrl && (
          <Button
            className='w-full mx-2'
            variant='outline'
            onClick={() => window.open(study.reportUrl!, '_blank')}>
            Download Report
          </Button>
        )}
      </div>

      <div>
        <TabBrowser
          tabs={['images', 'files', 'memos']}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab as any}
        />

        <div className='pt-2'>
          {currentTab === 'images' ? (
            <DicomStudyTable worklistId={Number(worklistId)} />
          ) : currentTab === 'files' ? (
            <AttachmentsTable worklistId={Number(worklistId)} />
          ) : (
            currentTab === 'memos' && <div>Messages functionality coming soon...</div>
          )}
        </div>
      </div>
    </div>
  )
}
