import logo from '@/assets/EpsilonHealth-MainLogo.png'
import { Button } from '@/components/ui/Button'
import { Input } from '@/components/ui/Input'
import { auth } from '@/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'

const Login: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (email && password) {
      try {
        await signInWithEmailAndPassword(auth, email, password)
      } catch (err: any) {
        setError(err.message || 'Login failed. Please try again.')
      }
    }
  }

  return (
    <div className='flex flex-col items-center justify-between h-full'>
      <img src={logo} alt='Epsilon Health' className='h-[30px]' draggable={false} />

      <div>
        <div className='flex flex-col items-center gap-2 mb-8'>
          <h1 className='font-light text-5xl text-slate-800'>Welcome</h1>
          <h2 className='text-lg text-slate-500'>Please sign in to your account</h2>
        </div>

        {error && <span className='text-red-500 mb-4 block text-center'>{error}</span>}

        <form className='w-80 flex flex-col gap-2' onSubmit={handleSubmit}>
          <Input
            placeholder='Email'
            value={email}
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <Input
            placeholder='Password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <Button className='w-full mt-4' type='submit' variant='outline'>
            Login
          </Button>
        </form>
      </div>

      <div className='text-sm text-slate-500'>
        Don't have an account?{' '}
        <a href='https://www.epsilon.health/' className='text-blue-500'>
          Join the waitlist
        </a>
      </div>
    </div>
  )
}

export default Login
