import { Button } from '@/components/ui/Button'
import { cn } from '@/lib/utils'

export const TabBrowser = ({
  tabs,
  currentTab,
  setCurrentTab,
  className,
}: {
  tabs: string[]
  currentTab: string
  setCurrentTab: (tab: any) => void
  className?: string
}) => {
  return (
    <div className={cn('border-b-[1px] flex flex-row w-full', className)}>
      {tabs.map((tab, index) => (
        <Tab active={currentTab === tab} onClick={() => setCurrentTab(tab)} key={index}>
          {tab}
        </Tab>
      ))}
    </div>
  )
}

const Tab = ({
  active,
  onClick,
  children,
}: {
  active: boolean
  onClick: () => void
  children: React.ReactNode
}) => (
  <Button
    variant='ghost'
    className={cn(
      'p-2 w-full capitalize overflow-x-hidden',
      active && 'border-[1px] border-b-0 rounded-b-none rounded-t-sm bg-slate-100'
    )}
    onClick={onClick}>
    {children}
  </Button>
)
